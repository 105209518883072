<template>
  <section>
    <b-row class="custom-row-table" v-if="items.length">
      <h5>{{ $t("admin.role.individualMedicina") }}</h5>
      <b-table
        striped
        :items="items"
        :fields="fields"
        table-variant="light"
        borderless
        small
      ></b-table>
    </b-row>
    <b-row class="custom-row-table" v-if="itemPackage.length">
      <h5>{{ $t("admin.role.packageMedicine") }}</h5>
      <b-table
        striped
        :items="itemPackage"
        :fields="fields"
        table-variant="light"
        borderless
        small
      >
        
          <template #row-details="row">
            <b-table
              striped
              :items="row.item.data.Componentes"
              :fields="fieldsPackage"
              table-variant="light"
              borderless
              small
            ></b-table>
          </template>
      </b-table>
    </b-row>
  </section>
  
</template>

<script>
import { NumberFormat } from '../helpers/NumberFormat';
export default {
  name: "TableBase",
  //props: ["headColor", "headText", "program"],
  data: () => ({
    items: [],
    itemPackage:[],
    pathName: ""
  }),
  computed: {
    fields() {
      return [
        
        {
          key: "description",
          label: this.translate("Description"),
        },
        {
          key: "amount",
          label: this.translate("Quantity"),
        },
         {
          key: "price",
          label: this.translate("Price"),
        },
        {
          key: 'priceTotal',
          label: this.translate('PriceTotal'),
        },
        { key: "actions", label: "" },
      ];
    },
    fieldsPackage() {
      return [
        {
          key: "CodigoAlterno",
          label: this.translate("Unit"),
        },
        {
          key: "Cantidad",
          label: this.translate("Quantity"),
        },
      ];
    },
  },
  created() {
    this.pathName = this.$route.params.name;
  },
  mounted() {
    let tableTabledata =
      JSON.parse(sessionStorage.getItem(this.pathName === "sspta" ? "arregloArticulos": "arrayMedicine")) || [];
    tableTabledata.forEach((el) => {
      el.priceTotal =
        (el.currencyFormat === 'Pesos' ? 'MXN ' : 'USD ') + NumberFormat(Number(el.price) * Number(el.amount));
      el.isActive = true;
      el.price = (el.currencyFormat === "Pesos" ? "MXN " : "USD ") + NumberFormat(el.price);
    });

    this.items = tableTabledata.filter((el) => el.name === "MEDICINA INDIVIDUAL");
    this.itemPackage = tableTabledata.filter((el) => el.name === "MEDICINA PAQUETE");
    if(this.itemPackage.length) this.itemPackage[0]._showDetails= true;
  },

  methods: {
    translate(name) {
      return this.$i18n.t("recipe.Table." + name);
    },
  },
};
</script>

<style scoped>
@import "../assets/css/global.css";

.custom-row-table {
  background-color: #fff;
  padding: 12px 0px 2px 0px;
  margin: 40px 0px 20px 0px;
  border-radius: 12px;
  overflow: hidden;
}
</style>
