<template>
  <b-container>
    <Owner />
    <b-row align-v="center">
      <b-col>
        <b-row>
          <NavbarInfo />
          <b-col>
            <div>
              <b-row class="text-center" align-v="center">
                <b-col md="8" class="text-left">
                  <!--aqui va la propuesta-->
                  <h3 class="title" v-if="pathName === 'sspta'">{{ $t("recipe.Title") }}</h3>
                  <h3 class="title" v-else>{{ $t("medicine.medicationManagement") }}</h3>
                  <p class="small-title">{{ $t("recipe.smallTitle") }}</p>
                </b-col>
                <b-col>
                  <router-link class="menu__link" to="/proposal">
                    <b-button variant="outline-primary" class="btn btn-blue-dark-outline ml-0">
                      {{ $t("recipe.Button") }} +
                    </b-button>
                  </router-link>
                </b-col>
              </b-row>

              <div class="mt-4">
                <TableBase headColor="#FFFFFF" headText="#000000" :program="true" />
              </div>

              <div class="mt-4" v-if="!loadingPDF && !loadingPDFMed">
                <h4>{{ $t("recipe.components") }}</h4>
                <TableBase
                  headColor="#FFFFFF"
                  headText="#000000"
                  :program="false"
                  :subtype="false"
                  :busy="loadingPDF || loadingPDFMed"
                />
              </div>

                <div class="mt-4" v-if="!loadingPDF && !loadingPDFMac">
                <h4>{{ $t('recipe.componentsMac') }}</h4>
                <TableBase
                  headColor="#FFFFFF"
                  headText="#000000"
                  :program="false"
                  :subtype="true"
                  :busy="loadingPDF || loadingPDFMac"
                />
              </div>
              <div class="text-center text-primary my-2" v-else>
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
              <b-row class="text-center float-right mb-4 mr-3 mt-3" align-v="center">
                <b-button class="btn btn-blue-dark mr-3" @click="sendData" v-if="!loadingConfirm">
                  {{ $t('recipe.Confirm') }}
                </b-button>
                <b-button class="btn btn-blue-dark-outline mr-3" disabled v-else>
                  <b-spinner small label="Small Spinner"></b-spinner>
                  {{ $t("admin.medicines.loading") }}
                </b-button>
                <b-button
                  class="btn btn-blue-dark-outline mr-3"
                  @click="exportToPDF"
                  v-if="dataHTML.length"
                >
                  {{ $t('recipe.PDF') }}
                </b-button>

                <b-button class="btn btn-blue-dark-outline mr-3" disabled v-else>
                  <b-spinner small label="Small Spinner"></b-spinner>

                  {{ $t("recipe.Loading") }}
                </b-button>
                <b-button
                  class="btn btn-blue-dark-outline"
                  @click="exportToPDFMed"
                  v-if="findMed"
                  :disabled="loadingMedicine"
                >
                  <span v-if="!loadingMedicine">{{ $t("recipe.PdfMedicine") }}</span>
                  <span v-else>
                    <b-spinner small label="Small Spinner"></b-spinner>
                    {{ $t("recipe.Loading") }}
                  </span>
                </b-button>

              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- PDF color  -->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="previewPdf"
      :paginate-elements-by-height="1200"
      :filename="`${nhc} ${patientName}`"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="94%"
      @hasDownloaded="hasDownloaded($event)"
      @beforeDownload="beforeDownload($event, 'Programs')"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <div v-for="(item, index) in dataHTML" :key="index">
          <section class="pdf-item">
            <p v-html="item && item.content"></p>
            <div v-if="item && item.table">
              <h4 v-if="country === 'MX'">Componentes Adicionales</h4>
              <h4 v-else>Additional Components</h4>
              <template v-if="color === 'VA'">
                <TableBase headColor="#A4C2F4" headText="#FFFFFF" :program="false" />
              </template>
              <template v-else-if="color.includes('V')">
                <TableBase headColor="#92D050" headText="#FFFFFF" :program="false" />
              </template>
              <template v-else-if="color.includes('A')">
                <TableBase headColor="#3A5EA6" headText="#FFFFFF" :program="false" />
              </template>
            </div>
            <p v-html="item && item.content2"></p>
          </section>
          <div class="html2pdf__page-break" />
        </div>
        <div v-for="(item, index) in listImages.imagenesRoad" :key="index + 10">
          <section class="pdf-item">
            <div class="content__img">
              <img :src="item" :alt="'image ' + index" style="width: 90%" />
            </div>
          </section>
          <div class="html2pdf__page-break" />
        </div>
      </section>
    </vue-html2pdf>

    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="previewPdf"
      :paginate-elements-by-height="1300"
      :filename="`MEDICINE ${nhc} ${patientName}`"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="letter"
      pdf-orientation="portrait"
      pdf-content-width="94%"
      @hasDownloaded="hasDownloadedPdfMed($event)"
      @beforeDownload="beforeDownload($event, 'Medicine')"
      ref="html2PdfMedicine"
    >
      <section slot="pdf-content">
        <div v-for="(item, index) in dataMed" :key="index">
          <section class="pdf-item">
            <p v-html="item && item.content"></p>
            <div v-if="item && item.table">
              <TableMedicine/>
            </div>
            <p v-html="item && item.content2"></p>
          </section>
          <div class="html2pdf__page-break" />
        </div>
      </section>
    </vue-html2pdf>
  </b-container>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import Owner from "@/components/Owner";
import NavbarInfo from "@/components/NavbarInfo";
import TableBase from "@/components/TableBase";
import TableMedicine from "@/components/TableMedicine";
import Swal from "sweetalert2";
import moment from "moment";
import { API_HUBSPOT, API_ROOT, API_UPLOAD_FILE, API_CENTRAL } from "../Constants/Api";
import {
  AlertClose,
  AlertConfirmHTML,
  AlertForm,
  AlertHTML,
  AlertLoading,
  ICON,
} from "../helpers/Alert";
import { ApiRequest } from "../helpers/ApiRequest";


export default {
  name: "Receta",
  components: {
    NavbarInfo,
    Owner,
    VueHtml2pdf,
    TableBase,
    TableMedicine,
  },
  data() {
    return {
      patientName: "",
      pathName: "",
      dataImage: "data:image/jpeg;base64,",
      listImages: { imagenesRoad: [] },
      nhc: "",
      med_assistance: "",
      doc_info: "",
      email: "",
      sede: "",
      color: "",
      programName: "",
      country: "",
      address: "",
      loadingPDF: true,
      dataHTML: [],
      dataMed: [],
      tableComponents: false,
      loadingConfirm: true,
      previewPdf: true,
      loadingPDFMed: false,
      programs: [],
      findMed: false,
      loadingMedicine: false,
      prodSurgery: [],
      prodLabs: [],
      prodTreatment: [],
      otherProductsQuotation: { "LAB.GRAL": [], CIRUGIA: [] },
      configRol: {},
      isLoadingConfig: false,
      labsNames: ["LAB.GRAL", "LABS"],
      loadingMac: false,
      findMac:false,
      dataMac: [],
      loadingPDFMac: false,
    };
  },
  computed: {
    timestamp: function() {
      let language = this.country === "MX" ? "es" : this.$i18n.locale;
      moment.locale(language);
      return moment().format("LL");
    },
    changeLanguage: function() {
      return this.$i18n.locale;
    },
    updateConfigRol() {
      return this.$store.state.configRol;
    },
    updateConfigPdf() {
      return this.$store.state.configPDF;
    },
  },
  watch: {
    changeLanguage: async function() {
      this.loadingPDF = true;
      this.loadingConfirm = true;
      this.timeLoading();
      await this.getDocument();
    },
    updateConfigRol() {
      this.configRol = this.updateConfigRol;
      if (this.configRol.recipe.ventana === false) {
        this.$router.push({ name: "Welcome" });
        AlertForm(
          `${this.$i18n.t("permissions.unauthorized")} ${this.$i18n.t("menu.Recipe")}`,
          this.$i18n.t("permissions.validateAdmin"),
          ICON.WARNING,
          "center",
          null
        );
      }
    },
    async updateConfigPdf() {
      if (this.updateConfigPdf) {
        await this.getDocument();
      }
    },
  },

  created() {
    this.configRol = this.$store.state.configRol;
    if (this.configRol.recipe?.ventana === false) {
      this.$router.push({ name: "Proposal" });
      AlertForm(
        `${this.$i18n.t("permissions.unauthorized")} ${this.$i18n.t("menu.Recipe")}`,
        this.$i18n.t("permissions.validateAdmin"),
        ICON.WARNING,
        "center",
        null
      );
    }
    this.timeLoading();
    this.pathName = this.$route.params.name;
  },
  async mounted() {
    this.patientName = sessionStorage.getItem("nombre");

    const Datos = await ApiRequest.get(`${API_ROOT}preconfiguracion/getAllConjuntoMedicamentos`);
    let ArtSeleccionado = sessionStorage.getItem("Article")?.split(",")[1];
    if (Datos.ok == true && this.pathName === "sspta") {
      let ConjuntosEncontrados = Datos.data;
      for (let n = 0; n < ConjuntosEncontrados.length; n++) {
        if (ConjuntosEncontrados[n].articulo == ArtSeleccionado) {
          let Sustituto =
            ConjuntosEncontrados[n].newArticulo + "," + ConjuntosEncontrados[n].newCodigoAlterno;
          //Variable para el PDF
          let NewArray = [];
          let ArticulosActuales = JSON.parse(sessionStorage.getItem("arregloArticulos"));
          let pipelineIdNow = ArticulosActuales[0].data.pipelineId;
          let UnidadNegocioNow = ArticulosActuales[0].data.UnidadNegocio;
          let dealstageNow = ArticulosActuales[0].data.dealstage;
          let PorcentajeMexicoNow = ArticulosActuales[0].data.PorcentajeMexico;
          let PorcentajeUSNow = ArticulosActuales[0].data.PorcentajeUS;
          for (let i = 0; i < ArticulosActuales.length; i++) {
            if (ArticulosActuales[i].description == ArtSeleccionado) {
              console.error("No se cuenta bec");
            } else if (
              ArticulosActuales[i].description == ConjuntosEncontrados[n].articulos[1].description
            ) {
              console.error("No se cuenta pgta");
            } else {
              NewArray.push(ArticulosActuales[i]);
            }
          }
          NewArray.push({
            name: ConjuntosEncontrados[n].articulos[0].name,
            value: ConjuntosEncontrados[n].articulos[0].value,
            amount: ConjuntosEncontrados[n].articulos[0].amount,
            price: ConjuntosEncontrados[n].articulos[0].price,
            description: ConjuntosEncontrados[n].articulos[0].description,
            currencyFormat: ConjuntosEncontrados[n].articulos[0].currencyFormat,
            data: {
              prod_id: ConjuntosEncontrados[n].articulos[0].data.prod_id,
              CodigoAlterno: ConjuntosEncontrados[n].articulos[0].data.CodigoAlterno,
              Articulo: ConjuntosEncontrados[n].articulos[0].data.Articulo,
              Unidad: ConjuntosEncontrados[n].articulos[0].data.Unidad,
              PrecioLista: ConjuntosEncontrados[n].articulos[0].data.PrecioLista,
              monedaPrecio: ConjuntosEncontrados[n].articulos[0].data.monedaPrecio,
              Estatus: ConjuntosEncontrados[n].articulos[0].data.Estatus,
              Categoria: ConjuntosEncontrados[n].articulos[0].data.Categoria,
              Familia: ConjuntosEncontrados[n].articulos[0].data.Familia,
              Necesidad: ConjuntosEncontrados[n].articulos[0].data.Necesidad,
              UnidadNegocio: UnidadNegocioNow,
              prod_ingreso: ConjuntosEncontrados[n].articulos[0].data.prod_ingreso,
              prod_update: ConjuntosEncontrados[n].articulos[0].data.prod_update,
              prod_idHS: ConjuntosEncontrados[n].articulos[0].data.prod_idHS,
              prod_paquete: ConjuntosEncontrados[n].articulos[0].data.prod_paquete,
              int_localidad_id: ConjuntosEncontrados[n].articulos[0].data.int_localidad_id,
              Precio2: ConjuntosEncontrados[n].articulos[0].data.Precio2,
              Precio3: ConjuntosEncontrados[n].articulos[0].data.Precio3,
              Ciclos: ConjuntosEncontrados[n].articulos[0].data.Ciclos,
              SSPTAMX: ConjuntosEncontrados[n].articulos[0].data.SSPTAMX,
              SSPTAUS: ConjuntosEncontrados[n].articulos[0].data.SSPTAUS,
              PorcentajeMexico: PorcentajeMexicoNow,
              PorcentajeUS: PorcentajeUSNow,
              pipelineId: pipelineIdNow,
              dealstage: dealstageNow,
              label_category: ConjuntosEncontrados[n].articulos[0].data.label_category,
              tipocard: ConjuntosEncontrados[n].articulos[0].data.tipocard,
            },
          });

          NewArray.push({
            name: ConjuntosEncontrados[n].articulos[1].name,
            value: ConjuntosEncontrados[n].articulos[1].value,
            amount: ConjuntosEncontrados[n].articulos[1].amount,
            price: ConjuntosEncontrados[n].articulos[1].price,
            description: ConjuntosEncontrados[n].articulos[1].description,
            currencyFormat: ConjuntosEncontrados[n].articulos[1].currencyFormat,
            data: {
              prod_id: ConjuntosEncontrados[n].articulos[1].data.prod_id,
              CodigoAlterno: ConjuntosEncontrados[n].articulos[1].data.CodigoAlterno,
              Articulo: ConjuntosEncontrados[n].articulos[1].data.Articulo,
              Unidad: ConjuntosEncontrados[n].articulos[1].data.Unidad,
              PrecioLista: ConjuntosEncontrados[n].articulos[1].data.PrecioLista,
              monedaPrecio: ConjuntosEncontrados[n].articulos[1].data.monedaPrecio,
              Estatus: ConjuntosEncontrados[n].articulos[1].data.Estatus,
              Categoria: ConjuntosEncontrados[n].articulos[1].data.Categoria,
              Familia: ConjuntosEncontrados[n].articulos[1].data.Familia,
              Necesidad: ConjuntosEncontrados[n].articulos[1].data.Necesidad,
              UnidadNegocio: UnidadNegocioNow,
              prod_ingreso: ConjuntosEncontrados[n].articulos[1].data.prod_ingreso,
              prod_update: ConjuntosEncontrados[n].articulos[1].data.prod_update,
              prod_idHS: ConjuntosEncontrados[n].articulos[1].data.prod_idHS,
              prod_paquete: ConjuntosEncontrados[n].articulos[1].data.prod_paquete,
              int_localidad_id: ConjuntosEncontrados[n].articulos[1].data.int_localidad_id,
              Precio2: ConjuntosEncontrados[n].articulos[1].data.Precio2,
              Precio3: ConjuntosEncontrados[n].articulos[1].data.Precio3,
              Ciclos: ConjuntosEncontrados[n].articulos[1].data.Ciclos,
              SSPTAMX: ConjuntosEncontrados[n].articulos[1].data.SSPTAMX,
              SSPTAUS: ConjuntosEncontrados[n].articulos[1].data.SSPTAUS,
              PorcentajeMexico: PorcentajeMexicoNow,
              PorcentajeUS: PorcentajeUSNow,
              pipelineId: pipelineIdNow,
              dealstage: dealstageNow,
              label_category: ConjuntosEncontrados[n].articulos[1].data.label_category,
              tipocard: ConjuntosEncontrados[n].articulos[1].data.tipocard,
            },
          });

          sessionStorage.setItem("Article", Sustituto);
          sessionStorage.setItem("arregloArticulos", JSON.stringify(NewArray));
        }
      }
    }

    this.programName =
      this.pathName === "sspta" ? sessionStorage.getItem("Article")?.split(",")[1] : "N/A";
    this.programs = JSON.parse(
      sessionStorage.getItem(this.pathName === "sspta" ? "arregloArticulos" : "arrayMedicine") ||
        "[]"
    );
    this.sede = JSON.parse(sessionStorage.getItem("sedeOrigen")).name;
    this.email = sessionStorage.getItem("email");
    this.country = sessionStorage.getItem("country");
    await this.getDocument();
  },
  methods: {
    timeLoading() {
      setTimeout(() => {
        this.loadingPDF = false;
        this.loadingConfirm = false;
      }, 7000);
    },
    async getDocument() {

      try {
        let HTML = "";
        [this.listImages, HTML] = await this.getImagesAPI();

        this.findMed = this.programs.find((el) => el.name.includes("MEDICINA")) ? true : false;
        if (this.findMed) {
          await this.getMedicinePDF();
        }
       this.findMac = this.programs.find((el) => el.name.includes('MORE')) ? true : false;
        if (this.findMac) {
          await this.getMacPDF();
        }

        this.doc_info = sessionStorage.getItem('doc_info');
        this.med_assistance = sessionStorage.getItem('med_assistance');
        this.nhc = sessionStorage.getItem('nhc');
        this.address = JSON.parse(sessionStorage.getItem('address'));
        this.dataHTML = this.convertHTML(HTML);
      } catch (error) {
        return error;
      }
    },
    convertHTML(dataHTML) {
      let splitBreak = dataHTML[0].base64.split("___breakPage___");
      let newHTML = splitBreak.map((el) => {
        let split = el.split("___");
        let newMessage = "",
          newMessage2 = "",
          _this = this,
          table = false;
        try {
          split.forEach((el, i) => {
            if (i % 2 === 0) {
              !table ? (newMessage += el) : (newMessage2 += el);
            } else {
              el = el.trim();
              if (el === "tableComponents" || el === "tableMedicine") {
                table = true;
                newMessage += "";
              } else {
                let data = _this[el];
                if (data) {
                  !table ? (newMessage += data) : (newMessage2 += data);
                } else {
                  !table ? (newMessage += "N/A") : (newMessage2 += "N/A");
                }
              }
            }
          });
          let validation = table;
          table = false;
          return {
            content: newMessage,
            table: validation,
            content2: newMessage2,
          };
        } catch (error) {
          AlertForm("Hubo un inconveniente al generar los datos al PDF", "", ICON.WARNING);
        }
      });
      return newHTML;
    },
    async getImagesAPI() {
      try {
        if (this.$store.state.configPDF) {
          this.color = this.getColorConfig();
        } else {
          this.color = this.getColor();
        }
        let language = this.country === "MX" ? "es" : this.$i18n.locale;
        let dataImg = await ApiRequest.get(
          `${API_UPLOAD_FILE}obtenerImagenes/color/${this.color}/pais/${this.country}/idioma/${language}`
        );
        const ImagesRoad = dataImg.data.filter((el) => el.tipo_img === "R");
        const htmlData = dataImg.data.filter((el) => el.tipo_img === "HTML");
        let indexImages = ImagesRoad.map((el) => el.posicion).sort((a, b) => a - b);
        let imagesSort = indexImages.map(
          (el) => this.dataImage + ImagesRoad.filter((num) => el === num.posicion)[0].base64
        );
        return [{ imagenesRoad: imagesSort }, htmlData];
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      }
    },

    async getMedicinePDF() {
      try {
        this.loadingMedicine = true;
        //let language = this.country === "MX" ? "es" : this.$i18n.locale;
        let medicinePDF = await ApiRequest.get(
          `${API_UPLOAD_FILE}obtenerImagenes/color/M/pais/${this.country}/idioma/${this.$i18n.locale}`
        );
        let dataMed = this.convertHTML(medicinePDF.data);
        this.dataMed = dataMed;
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      } finally {
        this.loadingMedicine = false;
      }
    },
    async getMacPDF() {
      try {
        this.loadingMac = true;
        //let language = this.country === "MX" ? "es" : this.$i18n.locale;
        let macPDF = await ApiRequest.get(
          `${API_UPLOAD_FILE}obtenerImagenes/color/M/pais/${this.country}/idioma/${this.$i18n.locale}`
        );
        let dataMac = this.convertHTML(macPDF.data);
        this.dataMac = dataMac;
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      } finally {
        this.loadingMac = false;
      }
    },
    getColor() {
      if (this.country === "US") {
        let articulo = sessionStorage
          .getItem("Article")
          ?.split(",")[0]
          .trim();
        let newProgram = sessionStorage.getItem("newPrograms");
        let edad = Number(sessionStorage.getItem("edad"));
        let { conocimiento_fertilidad: conocimiento } = JSON.parse(
          sessionStorage.getItem("conocimiento")
        );
        const verdeA = ["SAH A"];
        const azules = ["YSAH 2", "YSAH 3", "YSAH 4", "YSAH PRESERV"];
        const verdes = [
          "YSAH 1",
          "YSAH 2",
          "YSAH 3",
          "YSAH PRESERV",
          "YSAH ABC",
          "(1) YSAH ABC",
          "(1) SAH A",
        ];
        const respuestas = [
          `Nothing, I'm starting to inquire`,
          "Little, I have consulted some things",
          "I have knowledge of the subject",
          "I have spoken with doctors about the subject",
          "I know a lot about the subject",
        ];

        const respuestasNewScore = [
          `I've tried to be a mom on my own and didn't succeed`,
          `I've tried to be with specialists and didn't succeed`,
          `I already have children but I want to be a mother again`,
        ];
        if (newProgram || !articulo) {
          if (edad <= 27) {
            return "V";
          }
          if (edad > 27 && edad <= 34) {
            if (conocimiento === respuestasNewScore[0]) return "V";
            if (conocimiento === respuestasNewScore[1] || conocimiento === respuestasNewScore[2])
              return "A";
          }
          if (edad >= 35) return "A";
        }
        if (edad <= 27 && articulo === verdeA[0]) return "VA";
        if (edad <= 27 && verdes.includes(articulo)) return "V";
        if (
          edad <= 34 &&
          articulo === verdeA[0] &&
          (conocimiento === respuestas[0] ||
            conocimiento === respuestas[1] ||
            conocimiento === respuestasNewScore[0])
        ) {
          return "VA";
        }
        if (
          edad <= 34 &&
          verdes.includes(articulo) &&
          (conocimiento === respuestas[0] ||
            conocimiento === respuestas[1] ||
            conocimiento === respuestasNewScore[0])
        ) {
          return "V";
        }
        if (
          azules.includes(articulo) &&
          edad <= 34 &&
          (conocimiento == respuestas[2] ||
            conocimiento == respuestas[3] ||
            conocimiento == respuestas[4] ||
            conocimiento === respuestasNewScore[1] ||
            conocimiento === respuestasNewScore[2])
        ) {
          return "A";
        }
        if (edad >= 35) return "A";
      } else {
        let { color, score } = JSON.parse(sessionStorage.getItem("buyer-score"));
        if (color === "Azul" && score === "Amarillo") return "A-Ye";
        if (color === "Azul" && score === "Intermedio") return "A-In";
        if (color === "Azul" && score === "Intermedio") return "A-In-H";
        if (color === "Azul" && score === "Intermedio") return "A-In-L";
        if (color === "Azul" && score === "Blanco_Puntaje Minimo") return "A-Wh";
        if (color === "Verde" && score === "Amarillo") return "V-Ye";
        if (color === "Verde" && score === "Intermedio") return "V-In";
        if (color === "Verde" && score === "Intermedio") return "V-In-H";
        if (color === "Verde" && score === "Intermedio") return "V-In-L";
        if (color === "Verde" && score === "Blanco_Puntaje Minimo") return "V-Wh";
      }
      AlertForm("Error", this.$i18n.t("permissions.noAssociatedScore"), ICON.WARNING);
    },
    getColorConfig() {
      let { principal, opcional } = this.$store.state.configPDF;
      if (principal === "Azul" && opcional === null) return "A";
      if (principal === "Verde" && opcional === null) return "V";
      if (principal === "Azul" && opcional === "Amarillo") return "A-Ye";
      if (principal === "Azul" && opcional === "Intermedio") return "A-In";
      if (principal === "Azul" && opcional === "Intermedio-H") return "A-In-H";
      if (principal === "Azul" && opcional === "Intermedio-L") return "A-In-L";
      if (principal === "Azul" && opcional === "Blanco_Puntaje Minimo") return "A-Wh";
      if (principal === "Verde" && opcional === "Amarillo") return "V-Ye";
      if (principal === "Verde" && opcional === "Intermedio") return "V-In";
      if (principal === "Verde" && opcional === "Intermedio-H") return "V-In-H";
      if (principal === "Verde" && opcional === "Intermedio-L") return "V-In-L";
      if (principal === "Verde" && opcional === "Blanco_Puntaje Minimo") return "V-Wh";
      AlertForm("Error", this.$i18n.t("permissions.noAssociatedScore"), ICON.WARNING);
    },

    async updateContact() {
      const datos = {
        email: sessionStorage.getItem("email"),
      };
      let sedeOrigen = JSON.parse(sessionStorage.getItem("sedeOrigen")).name;
      let sedeDestino = JSON.parse(sessionStorage.getItem("sedeDestino"));
      sedeOrigen.country === "us"
        ? (datos.sucursal = sedeOrigen.name)
        : (datos.sucursal_mexico = sedeOrigen.name);
      sedeDestino.country === "us"
        ? (datos.sucursal_destino_defecto = sedeDestino.value)
        : (datos.sucursal_destino_defecto_mexico = sedeDestino.value);
      try {
        await ApiRequest.post(`${API_HUBSPOT}updateContact`, { datos });
        return true;
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
        return false;
      }
    },

    proccessConfirm(permitidos) {
      let deleteValidation = this.deleteProducts(permitidos, false);
      if (!deleteValidation) return;
      setTimeout(() => {
        Swal.fire({
          title: this.$i18n.t("admin.medicines.loading"),
          text: this.$i18n.t("recipe.Alert.msgLoading"),
          allowOutsideClick: false,
        });
        Swal.showLoading();
        this.$refs.html2Pdf.generatePdf();
      }, 0);
    },
    deleteProducts(permitidos, redirect = true) {
      let infoCategory = ["BEC", "BEC INTERNACIONAL", "TECNICA", "TECHNICS"];
      let arrayProducts = JSON.parse(sessionStorage.getItem("arregloArticulos"));
      let becTechArray = arrayProducts.filter((el) => infoCategory.includes(el.name));
      let otherProducts = arrayProducts.filter((el) => !infoCategory.includes(el.name));
      let becArray = [],
        technicsArray = [];
      if (permitidos.includes("BEC")) {
        becArray = becTechArray.filter((el) => ["BEC", "BEC INTERNACIONAL"].includes(el.name));
      }
      if (permitidos.includes("TECHNICS") || permitidos.includes("TECNICA")) {
        technicsArray = becTechArray.filter((el) => ["TECHNICS", "TECNICA"].includes(el.name));
      }
      arrayProducts = [...becArray, ...technicsArray, ...otherProducts];
      sessionStorage.setItem("arregloArticulos", JSON.stringify(arrayProducts));

      this.programs = arrayProducts;
      if (arrayProducts.length === 0) {
        AlertForm(
          this.$i18n.t("recipe.Alert.Warning"),
          this.$i18n.t("permissions.notSelectProduct"),
          ICON.WARNING,
          "center"
        );
        this.$router.push({ name: "Proposal" });
        return false;
      }
      if (redirect) {
        this.$router.push({ name: "Proposal" });
        return false;
      }
      return true;
    },
    async sendData() {
      //valida si tiene productos seleccionados
      if (!this.programs.length) {
        this.$router.push({ name: "Proposal" });
        return AlertForm(
          this.$i18n.t("recipe.Alert.Warning"),
          this.$i18n.t("permissions.notSelectProduct"),
          ICON.WARNING,
          "center"
        );
      }
      //valida si tiene sede destino seleccionado
      if (!sessionStorage.getItem("sedeDestino")) {
        return AlertForm(
          this.$i18n.t("recipe.Alert.Warning"),
          this.$i18n.t("permissions.notSelectBranch"),
          ICON.WARNING
        );
      }
      //valida si tiene med_assistance.owner_id asignado, si no retorna a welcome
      if (sessionStorage.getItem("flagOwner") === 0) {
        AlertForm(this.$i18n.t("recipe.Alert.Warning"), this.translate("noCreate"), ICON.WARNING);
        this.$router.push({ name: "Welcome" });
      }
      this.loadingConfirm = true;
      this.previewPdf = false;
      //valida control de negocio si puede crear negocio

      try {
        const data = await ApiRequest.post(`${API_ROOT}cotizacion/control-negocio`, {
          vid: sessionStorage.getItem("vid"),
          sede_origen: JSON.parse(sessionStorage.getItem("sedeOrigen")).name,
        });
        if (data.detalle_control.Negocios.length) {
          let html = data.detalle_control.Negocios.map((el) => {
            return `
            <b> ${this.translate("bussiness")}: </b> ${el.Negocio}<br>
            <b> ${this.translate("stage")}: </b> ${el.Etapa}<br>
            <b> ${this.translate("bussinessId")}: </b> ${el.NegocioId}<br>
            <b> ${this.translate("pipeline")}: </b> ${el.nombrePipeline}<br>
            <b> Pipeline ID: </b> ${el.pipelineId}<hr>
            `;
          });
          if (data.detalle_control.permitidos.length) {
            /*
            let permitidos = data.detalle_control.permitidos;
            if (this.country === 'US') {
              permitidos = permitidos.filter((el) => el !== 'TECNICA');
            } else {
              permitidos = permitidos.filter((el) => el !== 'TECHNICS');
            }
            html.push(`<b>${this.translate('onlyCreate')} ${permitidos.join(',')}, ${this.translate('continue')}</b>`);

            */
            if (this.country === "MX") {
              html.push(`<b>${this.translate("noBec")}</b>`);
            } else {
              html.push(`<b>${this.translate("noYASH")}</b>`);
            }
          } else {
            html.push(`<b>${this.translate("notAllowed")}</b>`);
          }

          if (!data.detalle_control.permitidos.length) {
            AlertHTML(this.translate("processBussiness"), html.join(""), ICON.WARNING);
            return this.deleteProducts(data.detalle_control.permitidos);
          }

          AlertConfirmHTML(
            this.translate("processBussiness"),
            html.join(""),
            ICON.WARNING,
            () => this.proccessConfirm(data.detalle_control.permitidos),
            () => this.deleteProducts(data.detalle_control.permitidos)
          );
        } else {
          Swal.fire({
            title: this.translate("createTitle"),
            text: this.translate("createText"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "var(--blue-dark)",
            cancelButtonColor: "var(--red-color)",
            confirmButtonText: this.translate("save"),
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              setTimeout(() => {
                Swal.fire({
                  title: this.$i18n.t("admin.medicines.loading"),
                  text: this.$i18n.t("recipe.Alert.msgLoading"),
                  allowOutsideClick: false,
                });
                Swal.showLoading();
                this.$refs.html2Pdf.generatePdf();
              }, 0);
            } else if (result.dismiss === "cancel") {
              this.loadingConfirm = false;
              AlertForm(this.translate("cancelTitle"), this.translate("cancelText"), ICON.WARNING);
            }
          });
        }
      } catch (err) {
        this.loadingConfirm = false;
        return AlertForm(
          `Error Code: ${err.status}`,
          err.statusText || this.translate("businessControl"),
          ICON.ERROR
        );
      }
    },
    exportToPDF() {
      AlertLoading(this.$i18n.t("recipe.loadingPdf"), this.$i18n.t("recipe.waitMoment"));
      this.loadingPDF = true;

      this.$refs.html2Pdf.generatePdf();
    },
    exportToPDFMed() {
      AlertLoading(this.$i18n.t("recipe.loadingPdfMed"), this.$i18n.t("recipe.waitMoment"));

      this.$refs.html2PdfMedicine.generatePdf();
    },
    convertirBase64(file, type) {
      try {
        let arrayBase64 = [];
        const self = this;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async function() {
          let base64 = reader.result;
          arrayBase64 = base64.split(",");
          if (type === "Programs") {
            //si tiene medicamentos se crea negocio y si es de mexico
            let responseMed = true;
           
            if (self.findMed && self.country === "MX") {
              responseMed = await self.businessMedicine();
              //responseLab = await self.businessLaboratory();
            }
            if (responseMed) {
              let responseBussiness = await self.createBusiness();
              if (responseBussiness) {
                await self.sendApiPdf(arrayBase64[1]);
              }
            }
            if (self.findMed) {
              self.$refs.html2PdfMedicine.generatePdf();
            }
          } else if (type === "Medicine") {
            await self.sendApiPdfMed(arrayBase64[1]);
          }
        };
      } catch (error) {
        AlertForm("Hubo un inconveniente al guardar pdf en Base de Datos", "", ICON.WARNING);
        this.loadingConfirm = false;
      }
    },
    async hasDownloaded() {
      AlertClose();
      this.loadingPDF = false;
    },
    async hasDownloadedPdfMed() {
      this.loadingPDFMed = false;
      this.previewPdf = true;
      AlertClose();
    },
    async beforeDownload({ html2pdf, options, pdfContent }, tipo) {
      if (!this.previewPdf) {
        let pdfData = await html2pdf()
          .set(options)
          .from(pdfContent)
          .toPdf()
          .get("pdf")
          .then((pdf) => {
            const totalPages = pdf.internal.getNumberOfPages();
            for (let i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFontSize(10);
              pdf.setTextColor(150);
              pdf.text(
                "Page " + i + " of " + totalPages,
                pdf.internal.pageSize.getWidth() * 0.88,
                pdf.internal.pageSize.getHeight() - 0.3
              );
            }
          })
          .outputPdf("blob", options.filename);
        await this.convertirBase64(pdfData, tipo);
        this.loadingPDF = false;
      }
    },

    alertSaved() {
      AlertForm(this.translate("successTitle"), this.translate("successText"), ICON.SUCCESS);
      this.terms = false;
      sessionStorage.removeItem("arrayMedicine");
      sessionStorage.removeItem("arregloArticulos");
      sessionStorage.removeItem("Article");
      this.$router.push({ name: "History" });
    },
    async createBusiness() {
      let validationUpdate = this.updateContact();
      if (!validationUpdate) return;

      let dataCardsQuotation = [];
      try {
        const allArrayArticles = this.programs.filter((el) => !el.name.includes("MEDICINA"));
        if (allArrayArticles.length === 0) return true;
        const arrayWithConfigPipeline = allArrayArticles.filter((el) =>
          el.data.pipelineId ? true : false
        );
        const arrayArticles = allArrayArticles.filter((el) => (el.data.pipelineId ? false : true));
        let becPipeline = arrayWithConfigPipeline.find((el) =>
          ["BEC", "BEC INTERNACIONAL"].includes(el.name)
        );

        let associationProduct = arrayArticles.find((el) => ["COVERAGE"].includes(el.name));

        let validateBecProteccion = arrayWithConfigPipeline.some((el) =>
          el.value.includes("BEC 3 PROT50")
        );
        if (validateBecProteccion) {
          associationProduct = {
            data: JSON.parse(sessionStorage.getItem("PROT 50")),
          };
        }

        if (becPipeline)
          dataCardsQuotation.push({
            ...becPipeline.data,
            PrecioLista: becPipeline.data.PrecioLista,
            Cantidad: "1",
            Relacionados: associationProduct ? [{ ...associationProduct.data, Cantidad: "1" }] : [],
          });

        // LABS TO CENTRAL/INTELISIS
        let becPipelineLab = arrayWithConfigPipeline.find((el) => this.labsNames.includes(el.name));
        if (becPipelineLab != null) {
          await this.businessLaboratory();
        }

        let filterPipeline = arrayWithConfigPipeline.filter(
          (el) =>
            ![
              "BEC",
              "BEC INTERNACIONAL",
              "COVERAGE",
              "MEDICINA INDIVIDUAL",
              "MEDICINA PAQUETE",
            ].includes(el.name)
        );
        filterPipeline.forEach((el) => {
          if (!this.labsNames.includes(el.name)) {
            dataCardsQuotation.push({
              ...el.data,
              Cantidad: el.amount,
              PrecioLista: el.data.PrecioLista,
            });
          }
        });
        this.terms = true;
        // Llama a getPercentUsList y espera a que se complete
        await this.getPercentUsList();
        //console.log('Data from getPercentUsList:', this.dataFromGetPercentUsList,getPercentList);
      } catch (err) {
        this.loadingConfirm = false;
        AlertForm("Error", this.$i18n.t("recipe.errorCreateProducts"), ICON.ERROR);
        return false;
      }

      if (dataCardsQuotation.length == 0) {
        return true;
      }
      // QUOTATION CARDS
      if (dataCardsQuotation.length) {
        try {
          let treatmentBranch = JSON.parse(sessionStorage.getItem("sedeDestino"));
          await ApiRequest.post(`${API_ROOT}cotizacion/card-cotizacion`, {
            vid: sessionStorage.getItem("vid"),
            firstname: sessionStorage.getItem("apellido"),
            lastname: sessionStorage.getItem("nombre"),
            owner_id: sessionStorage.getItem("ownerId"),
            // sede_destino: treatmentBranch.value,
            // desc_sede_destino: treatmentBranch.name,
            // sede_origen: JSON.parse(sessionStorage.getItem('sedeOrigen')).name,
            // sede_origen_key: JSON.parse(sessionStorage.getItem('sedeOrigen')).value,
            idSedeDestino: treatmentBranch.value,
            nombreSedeDestino: treatmentBranch.name,
            nombreSedeOrigen: JSON.parse(sessionStorage.getItem("sedeOrigen")).name,
            idSedeOrigen: JSON.parse(sessionStorage.getItem("sedeOrigen")).value,
            nhc: sessionStorage.getItem("nhc"),
            data: dataCardsQuotation,
            user_comision: this.$store.state.username || sessionStorage.getItem("userName"),
            usuario_creador: this.$store.state.username || localStorage.getItem("userName"),
          });

          return true;
        } catch (err) {
          this.loadingConfirm = false;
          if (err.status === 401) {
            AlertForm(this.translate("Warning"), this.translate("processBussiness"), ICON.WARNING);
            return false;
          }
          AlertForm(
            `Error Code: ${err.status}`,
            err.statusText || this.translate("errorText"),
            ICON.ERROR
          );
          return false;
        }
      }
    },

    async businessMedicine() {
      try {
        let filterMedicine = this.programs.filter(
          //(el) => ['MEDICINA INDIVIDUAL', 'MEDICINA PAQUETE','LAB.GRAL','LAB'].includes(el.name));
          (el) => el.name.includes("MEDICINA")
        );

        const medicines = [];
        const regeneraMedicines = [];

        for (const medicine of filterMedicine) {
          const { prod_id } = medicine.data;
          const {product: foundRegeneraProduct } = await await ApiRequest.get(`${API_ROOT}productos/productoRegeneraByProdId/${prod_id}`);
          
          const resultMedicine = {
            Articulo: medicine.data.Articulo,
            CodigoAlterno: medicine.data.CodigoAlterno,
            Categoria: medicine.name,
            Familia: "",
            Unidad: "",
            PrecioLista: this.pathName === "sspta" ? medicine.data.PrecioLista : medicine.data.precioLista,
            detalleDescuento: {
              PrecioLista: this.pathName === "sspta" ? medicine.data.PrecioLista : medicine.data.precioLista,
              PrecioEspecial: this.pathName === "sspta" ? null : medicine.data.PrecioEspecial,
              entregaMedicamento: false,
            },
            Estatus: "",
            monedaPrecio: medicine.data.monedaPrecio,
            PorcentajeMexico: medicine.data.PorcentajeMexico,
            PorcentajeUS: medicine.data.PorcentajeUS,
            SSPTAMX: "",
            SSPTAUS: "",
            createdAt: "",
            updatedAt: "",
            __v: 0,
            Cantidad: medicine.amount.toString(),
            Relacionados: [],
          };
          
          if(foundRegeneraProduct) {
            regeneraMedicines.push(resultMedicine);
          } else {
            medicines.push(resultMedicine);
          }
        }

        if(regeneraMedicines.length > 0) {
          const regenerabody = {
            vid: sessionStorage.getItem("vid"),
            owner_id: sessionStorage.getItem("ownerId"),
            sede_origen: JSON.parse(sessionStorage.getItem("sedeOrigen")).name,
            sede_origen_key: JSON.parse(sessionStorage.getItem("sedeOrigen")).value,
            sede_destino: 'REGEN',
            firstname: sessionStorage.getItem("nombre"),
            lastname: sessionStorage.getItem("apellido"),
            nhc: this.nhc,
            pais: this.country, // MX - US
            producto: "Laboratorio",
            data: regeneraMedicines,
            user_comision: this.$store.state.username || localStorage.getItem("userName"),
          };
          
          await this.saveCentral(regenerabody);
        } 
        if (medicines.length > 0) {
          const body = {
            vid: sessionStorage.getItem("vid"),
            owner_id: sessionStorage.getItem("ownerId"),
            sede_origen: JSON.parse(sessionStorage.getItem("sedeOrigen")).name,
            sede_origen_key: JSON.parse(sessionStorage.getItem("sedeOrigen")).value,
            sede_destino: JSON.parse(sessionStorage.getItem("sedeDestino")).value,
            firstname: sessionStorage.getItem("nombre"),
            lastname: sessionStorage.getItem("apellido"),
            nhc: this.nhc,
            pais: this.country, // MX - US
            producto: "MEDICAMENTOS",
            data: medicines,
            user_comision: this.$store.state.username || localStorage.getItem("userName"),
          };
          await this.saveCentral(body);
        }
        
        //await ApiRequest.post(`${API_ROOT}cotizacion/cotiza-indv-pln`, body );
        //Meter el metodo a central
        return true;
      } catch (err) {
        AlertForm(
          `Error Code: ${err.status}`,
          this.$i18n.t("permissions.errorMedicine"),
          ICON.ERROR
        );
        this.loadingConfirm = false;
        return false;
      }
    },
    async businessLaboratory() {
      try {
        let filterLaboratories = this.programs.filter((el) => this.labsNames.includes(el.name));

        //(el) => el.name.includes('MEDICINA'));
        // logica de negocio en medicina

        const laboratories = [];
        const regeneraLaboratories = [];

        for (const lab of filterLaboratories) {
          const { prod_id } = lab.data;
          const {product: foundRegeneraProduct } = await await ApiRequest.get(`${API_ROOT}productos/productoRegeneraByProdId/${prod_id}`);
          
          const resultLab = {
            Articulo: lab.data.Articulo,
            CodigoAlterno: lab.data.CodigoAlterno,
            Categoria: lab.name,
            Familia: "",
            Unidad: "",
            PrecioLista: this.pathName === "sspta" ? lab.data.PrecioLista : lab.data.precioLista,
            detalleDescuento: {
              PrecioLista: this.pathName === "sspta" ? lab.data.PrecioLista : lab.data.precioLista,
              PrecioEspecial: this.pathName === "sspta" ? null : lab.data.PrecioEspecial,
              entregaMedicamento: false,
            },
            Estatus: "",
            monedaPrecio: lab.data.monedaPrecio,
            PorcentajeMexico: lab.data.PorcentajeMexico,
            PorcentajeUS: lab.data.PorcentajeUS,
            SSPTAMX: "",
            SSPTAUS: "",
            createdAt: "",
            updatedAt: "",
            __v: 0,
            Cantidad: lab.amount.toString(),
            Relacionados: [],
          };
          
          if(foundRegeneraProduct && foundRegeneraProduct.bitRegenera) {
            regeneraLaboratories.push(resultLab);
          } else {
            laboratories.push(resultLab);
          }
        }

        if(regeneraLaboratories.length > 0) {
          const regenerabody = {
            vid: sessionStorage.getItem("vid"),
            owner_id: sessionStorage.getItem("ownerId"),
            sede_origen: JSON.parse(sessionStorage.getItem("sedeOrigen")).name,
            sede_origen_key: JSON.parse(sessionStorage.getItem("sedeOrigen")).value,
            sede_destino: 'REGEN',
            firstname: sessionStorage.getItem("nombre"),
            lastname: sessionStorage.getItem("apellido"),
            nhc: this.nhc,
            pais: this.country, // MX - US
            producto: "Laboratorio",
            data: regeneraLaboratories,
            user_comision: this.$store.state.username || localStorage.getItem("userName"),
          };
          await this.saveCentral(regenerabody);
        } 
        
        if(laboratories.length > 0) {
           const body = {
            vid: sessionStorage.getItem("vid"),
            owner_id: sessionStorage.getItem("ownerId"),
            sede_origen: JSON.parse(sessionStorage.getItem("sedeOrigen")).name,
            sede_origen_key: JSON.parse(sessionStorage.getItem("sedeOrigen")).value,
            sede_destino: JSON.parse(sessionStorage.getItem("sedeDestino")).value,
            firstname: sessionStorage.getItem("nombre"),
            lastname: sessionStorage.getItem("apellido"),
            nhc: this.nhc,
            pais: this.country, // MX - US
            producto: "Laboratorio",
            data: laboratories,
            user_comision: this.$store.state.username || localStorage.getItem("userName"),
          };
          await this.saveCentral(body);
        }

        //await ApiRequest.post(`${API_ROOT}cotizacion/cotiza-indv-pln`, body );
        //Meter el metodo a central
        return true;
      } catch (err) {
        AlertForm(
          `Error Code: ${err.status}`,
          this.$i18n.t("permissions.errorMedicine"),
          ICON.ERROR
        );
        this.loadingConfirm = false;
        return false;
      }
    },

    async sendApiPdf(data) {
      try {
        await ApiRequest.post(`${API_UPLOAD_FILE}`, {
          pdf_base64: data,
          nhc: this.nhc,
          correo: this.email,
        });
        if (!this.findMed) this.alertSaved();
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, this.$i18n.t("permissions.errorPdf"), ICON.ERROR);
        this.loadingConfirm = false;
      }
    },

    async sendApiPdfMed(base64) {
      try {
        await ApiRequest.post(`${API_UPLOAD_FILE}pdfMedicamentos`, {
          base64: base64,
          pais: this.country === "US" ? 1 : 2,
          nhc: this.nhc,
          correo: this.email,
        });
        this.alertSaved();
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, this.$i18n.t("permissions.errorPdf"), ICON.ERROR);
        this.loadingConfirm = false;
      }
    },
    translate(name) {
      return this.$i18n.t("recipe.Alert." + name);
    },

    async saveCentral(body) {
      let _id = await ApiRequest.get(`${API_ROOT}cotizacion/getId`, body);
      //Obtenemos el token
      var myHeadersT = new Headers();
      myHeadersT.append("Content-Type", "application/x-www-form-urlencoded");
      var urlencodedT = new URLSearchParams();
      urlencodedT.append("key", "597633da-228f-10bd-cb29-b634ae8539aa");
      var requestOptionsT = {
        method: "POST",
        headers: myHeadersT,
        body: urlencodedT,
        redirect: "follow",
      };
      let date = new Date();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      fetch(`${API_CENTRAL}validateUser`, requestOptionsT)
        .then((response) => response.text())
        .then(async (resultT) => {
          var myHeaders = new Headers();
          myHeaders.append("authorization", JSON.parse(resultT).token);
          myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

          //Declaramos los parametros de envio
          var dataNuevo = new URLSearchParams();
          dataNuevo.append("nhc", body.nhc);
          dataNuevo.append("idNegocioHS", 0);
          dataNuevo.append("monedaID", body.pais == "MX" ? 1 : 2);
          dataNuevo.append("emailagente", body.user_comision);
          dataNuevo.append("fechaganada", year + "/" + month + "/" + day);
          dataNuevo.append("tipoOportunidad", 0);
          dataNuevo.append("IDmeditex", body.pais == "MX" ? "" : body.nhc);
          dataNuevo.append("empresaOrigen", body.sede_origen_key);
          dataNuevo.append("empresaDestino", body.sede_destino);
          dataNuevo.append("accion", "Ganada");
          dataNuevo.append("IdSolicitudIM", _id.msg);
          dataNuevo.append("ownerId", body.owner_id);
          dataNuevo.append("nhcdefinitivomex", body.nhc);
          dataNuevo.append("UsuarioEnvioIM", body.user_comision);
          dataNuevo.append("tipoCambio", "1");
          dataNuevo.append("Monto", "1");
          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: dataNuevo,
            redirect: "follow",
          };

          fetch(`${API_CENTRAL}apipost/NuevoNegocioGanado`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
              //insertar a mongo la cotizacion
              try {
                this.data = JSON.parse(result).addenda[0];
                let _nNegocio = JSON.parse(result).addenda[0];

                //Mandamos los valores de el detalle de producto
                var myHeaders2 = new Headers();
                myHeaders2.append("authorization", JSON.parse(resultT).token);
                myHeaders2.append("Content-Type", "application/x-www-form-urlencoded");

                const _body = [];
                body.data.forEach((item) => {
                  let _itemPush = {
                    articulo: item.Articulo,
                    BDNegocioID: _nNegocio.negprod_id,
                    cantidad: item.Cantidad,
                    preciolista: item.PrecioLista,
                    preciofinal: item.PrecioLista,
                    unidad: item.Unidad == "" ? item.Unidad : "serv",
                    localidad: body.pais == "MX" ? 1 : 2,
                  };
                  _body.push(_itemPush);
                });
                //Declaramos los parametros de envio
                var dataNuevoDet = new URLSearchParams();
                dataNuevoDet.append("productos", JSON.stringify(_body));
                var requestOptions = {
                  method: "POST",
                  headers: myHeaders2,
                  body: dataNuevoDet,
                  redirect: "follow",
                };

                fetch(`${API_CENTRAL}apipost/NuevoNegocioGanadoDetalle`, requestOptions)
                  .then((response) => response.text())
                  .then((result2) => {
                    try {
                      this.data = JSON.parse(result2).addenda[0];
                    } catch {
                      AlertForm(
                        `Error Code: ${JSON.parse(result2).valido}`,
                        JSON.parse(result2).mensaje,
                        ICON.ERROR
                      );
                    }
                  })
                  .catch((error) => {
                    if (error !== "Data Failure") {
                      AlertForm(`Error Code: ${error}`, error, ICON.ERROR);
                    } else {
                      return;
                    }
                  });
              } catch {
                AlertForm(
                  `Error Code: ${JSON.parse(result).valido}`,
                  JSON.parse(result).mensaje,
                  ICON.ERROR
                );
              }
            })
            .catch((error) => {
              if (error !== "Data Failure") {
                AlertForm(`Error Code: ${error}`, error, ICON.ERROR);
              } else {
                return;
              }
            });
        })
        .catch((error) => console.error("error", error));
    },
    async getPercentUsList() {
      try {
        this.isLoadingConfig = true;

        let response = await ApiRequest.get(`${API_ROOT}islamatrizpredefinida/list`);
        //console.log('response :',response)
        let responseData = response.lista;
        //console.log('responseData :',responseData);
        //console.log('response lista--',response.lista)
        let data = responseData.map((item) => ({
          id: item._id,
          isla: item.isla,
          nombrematriz: item.nombrematriz,
          // percent: {
          //   Isla: item.porcentajeisla,
          //   Matriz: item.porcentajematriz,
          // },
          Isla: item.porcentajeisla,
          Matriz: item.porcentajematriz,
        }));
        //console.log('response data:',responseData)
        //console.log('Formatted data for USA:', data);

        // Guarda los datos en la instancia de Vue para que puedan ser accesibles desde otro método
        this.dataFromGetPercentUsList = data;
      } catch (err) {
        if (err.status !== 401) {
          AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
        }
      } finally {
        this.isLoadingConfig = false;
      }
    },
  },
};
</script>
<style scoped>
@import "../assets/css/global.css";

.title {
  text-align: left;
  margin: 30px 30px 10px 0px;
}

.small-title {
  font-size: 18px;
  color: #b9b9b9;
  margin-left: 12px;
}

/* styles PDF */

.container-pdf {
  margin-top: -100px;
}
.pdf-item {
  display: flex;
  flex-direction: column;
  padding: 20px 0 0 10%;
  max-height: 950px;
}

.mt-50 {
  margin-top: -50px;
}
.mt-100 {
  margin-top: -100px;
}
.mt-150 {
  margin-top: -150px;
}
.mt-200 {
  margin-top: -200px;
}
.mt-250 {
  margin-top: -250px;
}
.mt-300 {
  margin-top: -300px;
}

p {
  color: black;
}
</style>