var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "custom-row-table" },
    [
      _c("b-table", {
        attrs: {
          striped: "",
          hover: "",
          items: _vm.items,
          fields: _vm.fields,
          "table-variant": "light",
          borderless: "",
          "head-variant": _vm.headColor
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }