
<template>
  <b-row class="custom-row-table">
    <b-table striped hover :items="items" :fields="fields" table-variant="light" borderless :head-variant="headColor">
    </b-table>
  </b-row>
</template>

<script>
import { API_ROOT} from '../Constants/Api';
import { ApiRequest } from '../helpers/ApiRequest';
import { NumberFormat } from '../helpers/NumberFormat';
export default {
  name: 'TableBase',
  props: ['headColor', 'headText', 'program', 'busy','subtype'],
  data: () => ({
    items: [],
    pathName: '',
  }),
  computed: {
    fields() {
      return [
        {
          key: 'name', //name
          sortable: true,
          label: this.translate('Unit'),
          thStyle: {
            backgroundColor: this.headColor,
            color: this.headText,
          },
        },
        {
          key: 'description',
          label: this.translate('Description'),
          thStyle: {
            backgroundColor: this.headColor,
            color: this.headText,
          },
        },
        {
          key: 'amount',
          label: this.translate('Quantity'),
          thStyle: {
            backgroundColor: this.headColor,
            color: this.headText,
          },
        },
        {
          key: 'price',
          label: this.translate('Price'),
          thStyle: {
            backgroundColor: this.headColor,
            color: this.headText,
          },
        },
        {
          key: 'priceTotal',
          label: this.translate('PriceTotal'),
          thStyle: {
            backgroundColor: this.headColor,
            color: this.headText,
          },
        },
      ];
    },
  },

  created() {
    this.pathName = this.$route.params.name;
  },
  async mounted() {

    const Datos = await ApiRequest.get(`${API_ROOT}preconfiguracion/getAllConjuntoMedicamentos`);
    let ArtSeleccionado = sessionStorage.getItem('Article')?.split(',')[1]
    if(Datos.ok == true && this.pathName === 'sspta'){
      let ConjuntosEncontrados= Datos.data
      for(let n=0; n<ConjuntosEncontrados.length; n++){
        if(ConjuntosEncontrados[n].articulo== ArtSeleccionado){
          let Sustituto = ((ConjuntosEncontrados[n].newArticulo)) +","+((ConjuntosEncontrados[n].newCodigoAlterno))
          //Variable para el PDF
          let NewArray = []
          let ArticulosActuales= JSON.parse(sessionStorage.getItem('arregloArticulos'))
          let pipelineIdNow	= (ArticulosActuales[0].data).pipelineId
          let dealstageNow = (ArticulosActuales[0].data).dealstage
          let UnidadNegocioNow	= (ArticulosActuales[0].data).UnidadNegocio
          let PorcentajeMexicoNow	= (ArticulosActuales[0].data).PorcentajeMexico
          let PorcentajeUSNow	= (ArticulosActuales[0].data).PorcentajeUS
          for(let i=0; i<ArticulosActuales.length ; i++){
            if(ArticulosActuales[i].description == ArtSeleccionado){
              console.log("No se cuenta bec")
            }else if(ArticulosActuales[i].description == (ConjuntosEncontrados[n].articulos)[1].description){
              console.log("No se cuenta pgta")
            }else{
              NewArray.push(ArticulosActuales[i])
            }
          }
          NewArray.push(
            {"name":ConjuntosEncontrados[n].articulos[0].name,
            "value":ConjuntosEncontrados[n].articulos[0].value,
            "amount":ConjuntosEncontrados[n].articulos[0].amount,
            "price":ConjuntosEncontrados[n].articulos[0].price,
            "description":ConjuntosEncontrados[n].articulos[0].description,
            "currencyFormat":ConjuntosEncontrados[n].articulos[0].currencyFormat,
            "data":{
              "prod_id":(ConjuntosEncontrados[n].articulos[0].data).prod_id,
              "CodigoAlterno":(ConjuntosEncontrados[n].articulos[0].data).CodigoAlterno,
              "Articulo":(ConjuntosEncontrados[n].articulos[0].data).Articulo,
              "Unidad":(ConjuntosEncontrados[n].articulos[0].data).Unidad,
              "PrecioLista":(ConjuntosEncontrados[n].articulos[0].data).PrecioLista,
              "monedaPrecio":(ConjuntosEncontrados[n].articulos[0].data).monedaPrecio,
              "Estatus":(ConjuntosEncontrados[n].articulos[0].data).Estatus,
              "Categoria":(ConjuntosEncontrados[n].articulos[0].data).Categoria,
              "Familia":(ConjuntosEncontrados[n].articulos[0].data).Familia,
              "Necesidad":(ConjuntosEncontrados[n].articulos[0].data).Necesidad,
              "UnidadNegocio": UnidadNegocioNow,
              "prod_ingreso":(ConjuntosEncontrados[n].articulos[0].data).prod_ingreso,
              "prod_update":(ConjuntosEncontrados[n].articulos[0].data).prod_update,
              "prod_idHS":(ConjuntosEncontrados[n].articulos[0].data).prod_idHS,
              "prod_paquete":(ConjuntosEncontrados[n].articulos[0].data).prod_paquete,
              "int_localidad_id":(ConjuntosEncontrados[n].articulos[0].data).int_localidad_id,
              "Precio2":(ConjuntosEncontrados[n].articulos[0].data).Precio2,
              "Precio3":(ConjuntosEncontrados[n].articulos[0].data).Precio3,
              "Ciclos":(ConjuntosEncontrados[n].articulos[0].data).Ciclos,
              "SSPTAMX":(ConjuntosEncontrados[n].articulos[0].data).SSPTAMX,
              "SSPTAUS":(ConjuntosEncontrados[n].articulos[0].data).SSPTAUS,
              "PorcentajeMexico":PorcentajeMexicoNow,
              "PorcentajeUS":PorcentajeUSNow,
              "pipelineId":pipelineIdNow,
              "dealstage":dealstageNow,
              "label_category":(ConjuntosEncontrados[n].articulos[0].data).label_category,
              "tipocard":(ConjuntosEncontrados[n].articulos[0].data).tipocard
            }})
           
          NewArray.push(
            {"name":ConjuntosEncontrados[n].articulos[1].name,
            "value":ConjuntosEncontrados[n].articulos[1].value,
            "amount":ConjuntosEncontrados[n].articulos[1].amount,
            "price":ConjuntosEncontrados[n].articulos[1].price,
            "description":ConjuntosEncontrados[n].articulos[1].description,
            "currencyFormat":ConjuntosEncontrados[n].articulos[1].currencyFormat,
            "data":{
              "prod_id":(ConjuntosEncontrados[n].articulos[1].data).prod_id,
              "CodigoAlterno":(ConjuntosEncontrados[n].articulos[1].data).CodigoAlterno,
              "Articulo":(ConjuntosEncontrados[n].articulos[1].data).Articulo,
              "Unidad":(ConjuntosEncontrados[n].articulos[1].data).Unidad,
              "PrecioLista":(ConjuntosEncontrados[n].articulos[1].data).PrecioLista,
              "monedaPrecio":(ConjuntosEncontrados[n].articulos[1].data).monedaPrecio,
              "Estatus":(ConjuntosEncontrados[n].articulos[1].data).Estatus,
              "Categoria":(ConjuntosEncontrados[n].articulos[1].data).Categoria,
              "Familia":(ConjuntosEncontrados[n].articulos[1].data).Familia,
              "Necesidad":(ConjuntosEncontrados[n].articulos[1].data).Necesidad,
              "UnidadNegocio": UnidadNegocioNow,
              "prod_ingreso":(ConjuntosEncontrados[n].articulos[1].data).prod_ingreso,
              "prod_update":(ConjuntosEncontrados[n].articulos[1].data).prod_update,
              "prod_idHS":(ConjuntosEncontrados[n].articulos[1].data).prod_idHS,
              "prod_paquete":(ConjuntosEncontrados[n].articulos[1].data).prod_paquete,
              "int_localidad_id":(ConjuntosEncontrados[n].articulos[1].data).int_localidad_id,
              "Precio2":(ConjuntosEncontrados[n].articulos[1].data).Precio2,
              "Precio3":(ConjuntosEncontrados[n].articulos[1].data).Precio3,
              "Ciclos":(ConjuntosEncontrados[n].articulos[1].data).Ciclos,
              "SSPTAMX":(ConjuntosEncontrados[n].articulos[1].data).SSPTAMX,
              "SSPTAUS":(ConjuntosEncontrados[n].articulos[1].data).SSPTAUS,
              "PorcentajeMexico":PorcentajeMexicoNow,
              "PorcentajeUS":PorcentajeUSNow,
              "pipelineId":pipelineIdNow,
              "dealstage":dealstageNow,
              "label_category":(ConjuntosEncontrados[n].articulos[1].data).label_category,
              "tipocard":(ConjuntosEncontrados[n].articulos[1].data).tipocard
            }})


          sessionStorage.setItem('Article',Sustituto)
          sessionStorage.setItem('arregloArticulos',JSON.stringify(NewArray))
        }
      }
    } 

    let tableTabledata =
      JSON.parse(sessionStorage.getItem(this.pathName === 'sspta' ? 'arregloArticulos' : 'arrayMedicine')) || [];

    tableTabledata.forEach((el) => {
      el.priceTotal =
        (el.currencyFormat === 'Pesos' ? 'MXN ' : 'USD ') + NumberFormat(Number(el.price) * Number(el.amount));
      el.price = (el.currencyFormat === 'Pesos' ? 'MXN ' : 'USD ') + NumberFormat(el.price);
    });

    if (this.program) {
      this.items = tableTabledata.filter((el) => ['BEC INTERNACIONAL', 'COVERAGE', 'BEC'].includes(el.name));
      if (this.items.some((el) => el.description === 'BEC 3 PROT50')) {
        let prot = JSON.parse(sessionStorage.getItem('PROT 50'));
        let addItem = {
          name: prot.Categoria,
          value: prot.Articulo,
          amount: '1',
          price: (prot.monedaPrecio === 'Pesos' ? 'MXN ' : 'USD ') + NumberFormat(prot.PrecioLista),
          description: prot.CodigoAlterno,
          currencyFormat: prot.monedaPrecio,
          priceTotal: '',
        };
        addItem.priceTotal =
          (prot.monedaPrecio === 'Pesos' ? 'MXN ' : 'USD ') +
          NumberFormat(Number(prot.PrecioLista) * Number(addItem.amount));
        this.items.push(addItem);
      }
    } 
    
    if (!this.program && !this.subtype){
      this.items = tableTabledata.filter((el) => !['BEC INTERNACIONAL', 'COVERAGE', 'BEC','MORE'].includes(el.name));
    }
     if (!this.program && this.subtype){
      this.items = tableTabledata.filter((el) => ['MORE'].includes(el.name));
    }

  },

  methods: {
    translate(name) {
      return this.$i18n.t('recipe.Table.' + name);
    },
  },
};
</script>

<style scoped>
@import '../assets/css/global.css';

.custom-row-table {
  background-color: #fff;
  padding: 0;
  margin: 0;
  /* padding: 12px 0px 2px 0px;
  margin: 40px 0px 20px 0px; */
  /* border-radius: 12px; */
  /* overflow: hidden; */
}

.textDonante {
  text-decoration: line-through;
  color: red;
}
</style>