var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    [
      _c("Owner"),
      _c(
        "b-row",
        { attrs: { "align-v": "center" } },
        [
          _c(
            "b-col",
            [
              _c(
                "b-row",
                [
                  _c("NavbarInfo"),
                  _c("b-col", [
                    _c(
                      "div",
                      [
                        _c(
                          "b-row",
                          {
                            staticClass: "text-center",
                            attrs: { "align-v": "center" }
                          },
                          [
                            _c(
                              "b-col",
                              { staticClass: "text-left", attrs: { md: "8" } },
                              [
                                _vm.pathName === "sspta"
                                  ? _c("h3", { staticClass: "title" }, [
                                      _vm._v(_vm._s(_vm.$t("recipe.Title")))
                                    ])
                                  : _c("h3", { staticClass: "title" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "medicine.medicationManagement"
                                          )
                                        )
                                      )
                                    ]),
                                _c("p", { staticClass: "small-title" }, [
                                  _vm._v(_vm._s(_vm.$t("recipe.smallTitle")))
                                ])
                              ]
                            ),
                            _c(
                              "b-col",
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "menu__link",
                                    attrs: { to: "/proposal" }
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass:
                                          "btn btn-blue-dark-outline ml-0",
                                        attrs: { variant: "outline-primary" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("recipe.Button")) +
                                            " + "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "mt-4" },
                          [
                            _c("TableBase", {
                              attrs: {
                                headColor: "#FFFFFF",
                                headText: "#000000",
                                program: true
                              }
                            })
                          ],
                          1
                        ),
                        !_vm.loadingPDF && !_vm.loadingPDFMed
                          ? _c(
                              "div",
                              { staticClass: "mt-4" },
                              [
                                _c("h4", [
                                  _vm._v(_vm._s(_vm.$t("recipe.components")))
                                ]),
                                _c("TableBase", {
                                  attrs: {
                                    headColor: "#FFFFFF",
                                    headText: "#000000",
                                    program: false,
                                    subtype: false,
                                    busy: _vm.loadingPDF || _vm.loadingPDFMed
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.loadingPDF && !_vm.loadingPDFMac
                          ? _c(
                              "div",
                              { staticClass: "mt-4" },
                              [
                                _c("h4", [
                                  _vm._v(_vm._s(_vm.$t("recipe.componentsMac")))
                                ]),
                                _c("TableBase", {
                                  attrs: {
                                    headColor: "#FFFFFF",
                                    headText: "#000000",
                                    program: false,
                                    subtype: true,
                                    busy: _vm.loadingPDF || _vm.loadingPDFMac
                                  }
                                })
                              ],
                              1
                            )
                          : _c(
                              "div",
                              { staticClass: "text-center text-primary my-2" },
                              [
                                _c("b-spinner", {
                                  staticClass: "align-middle"
                                }),
                                _c("strong", [_vm._v("Loading...")])
                              ],
                              1
                            ),
                        _c(
                          "b-row",
                          {
                            staticClass:
                              "text-center float-right mb-4 mr-3 mt-3",
                            attrs: { "align-v": "center" }
                          },
                          [
                            !_vm.loadingConfirm
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "btn btn-blue-dark mr-3",
                                    on: { click: _vm.sendData }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("recipe.Confirm")) +
                                        " "
                                    )
                                  ]
                                )
                              : _c(
                                  "b-button",
                                  {
                                    staticClass:
                                      "btn btn-blue-dark-outline mr-3",
                                    attrs: { disabled: "" }
                                  },
                                  [
                                    _c("b-spinner", {
                                      attrs: {
                                        small: "",
                                        label: "Small Spinner"
                                      }
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("admin.medicines.loading")
                                        ) +
                                        " "
                                    )
                                  ],
                                  1
                                ),
                            _vm.dataHTML.length
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass:
                                      "btn btn-blue-dark-outline mr-3",
                                    on: { click: _vm.exportToPDF }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("recipe.PDF")) + " "
                                    )
                                  ]
                                )
                              : _c(
                                  "b-button",
                                  {
                                    staticClass:
                                      "btn btn-blue-dark-outline mr-3",
                                    attrs: { disabled: "" }
                                  },
                                  [
                                    _c("b-spinner", {
                                      attrs: {
                                        small: "",
                                        label: "Small Spinner"
                                      }
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("recipe.Loading")) +
                                        " "
                                    )
                                  ],
                                  1
                                ),
                            _vm.findMed
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "btn btn-blue-dark-outline",
                                    attrs: { disabled: _vm.loadingMedicine },
                                    on: { click: _vm.exportToPDFMed }
                                  },
                                  [
                                    !_vm.loadingMedicine
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("recipe.PdfMedicine"))
                                          )
                                        ])
                                      : _c(
                                          "span",
                                          [
                                            _c("b-spinner", {
                                              attrs: {
                                                small: "",
                                                label: "Small Spinner"
                                              }
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("recipe.Loading")
                                                ) +
                                                " "
                                            )
                                          ],
                                          1
                                        )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "vue-html2pdf",
        {
          ref: "html2Pdf",
          attrs: {
            "show-layout": false,
            "float-layout": true,
            "enable-download": false,
            "preview-modal": _vm.previewPdf,
            "paginate-elements-by-height": 1200,
            filename: _vm.nhc + " " + _vm.patientName,
            "pdf-quality": 2,
            "manual-pagination": true,
            "pdf-format": "a4",
            "pdf-orientation": "portrait",
            "pdf-content-width": "94%"
          },
          on: {
            hasDownloaded: function($event) {
              return _vm.hasDownloaded($event)
            },
            beforeDownload: function($event) {
              return _vm.beforeDownload($event, "Programs")
            }
          }
        },
        [
          _c(
            "section",
            { attrs: { slot: "pdf-content" }, slot: "pdf-content" },
            [
              _vm._l(_vm.dataHTML, function(item, index) {
                return _c("div", { key: index }, [
                  _c("section", { staticClass: "pdf-item" }, [
                    _c("p", {
                      domProps: { innerHTML: _vm._s(item && item.content) }
                    }),
                    item && item.table
                      ? _c(
                          "div",
                          [
                            _vm.country === "MX"
                              ? _c("h4", [_vm._v("Componentes Adicionales")])
                              : _c("h4", [_vm._v("Additional Components")]),
                            _vm.color === "VA"
                              ? [
                                  _c("TableBase", {
                                    attrs: {
                                      headColor: "#A4C2F4",
                                      headText: "#FFFFFF",
                                      program: false
                                    }
                                  })
                                ]
                              : _vm.color.includes("V")
                              ? [
                                  _c("TableBase", {
                                    attrs: {
                                      headColor: "#92D050",
                                      headText: "#FFFFFF",
                                      program: false
                                    }
                                  })
                                ]
                              : _vm.color.includes("A")
                              ? [
                                  _c("TableBase", {
                                    attrs: {
                                      headColor: "#3A5EA6",
                                      headText: "#FFFFFF",
                                      program: false
                                    }
                                  })
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      : _vm._e(),
                    _c("p", {
                      domProps: { innerHTML: _vm._s(item && item.content2) }
                    })
                  ]),
                  _c("div", { staticClass: "html2pdf__page-break" })
                ])
              }),
              _vm._l(_vm.listImages.imagenesRoad, function(item, index) {
                return _c("div", { key: index + 10 }, [
                  _c("section", { staticClass: "pdf-item" }, [
                    _c("div", { staticClass: "content__img" }, [
                      _c("img", {
                        staticStyle: { width: "90%" },
                        attrs: { src: item, alt: "image " + index }
                      })
                    ])
                  ]),
                  _c("div", { staticClass: "html2pdf__page-break" })
                ])
              })
            ],
            2
          )
        ]
      ),
      _c(
        "vue-html2pdf",
        {
          ref: "html2PdfMedicine",
          attrs: {
            "show-layout": false,
            "float-layout": true,
            "enable-download": false,
            "preview-modal": _vm.previewPdf,
            "paginate-elements-by-height": 1300,
            filename: "MEDICINE " + _vm.nhc + " " + _vm.patientName,
            "pdf-quality": 2,
            "manual-pagination": true,
            "pdf-format": "letter",
            "pdf-orientation": "portrait",
            "pdf-content-width": "94%"
          },
          on: {
            hasDownloaded: function($event) {
              return _vm.hasDownloadedPdfMed($event)
            },
            beforeDownload: function($event) {
              return _vm.beforeDownload($event, "Medicine")
            }
          }
        },
        [
          _c(
            "section",
            { attrs: { slot: "pdf-content" }, slot: "pdf-content" },
            _vm._l(_vm.dataMed, function(item, index) {
              return _c("div", { key: index }, [
                _c("section", { staticClass: "pdf-item" }, [
                  _c("p", {
                    domProps: { innerHTML: _vm._s(item && item.content) }
                  }),
                  item && item.table
                    ? _c("div", [_c("TableMedicine")], 1)
                    : _vm._e(),
                  _c("p", {
                    domProps: { innerHTML: _vm._s(item && item.content2) }
                  })
                ]),
                _c("div", { staticClass: "html2pdf__page-break" })
              ])
            }),
            0
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }