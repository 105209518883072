var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _vm.items.length
        ? _c(
            "b-row",
            { staticClass: "custom-row-table" },
            [
              _c("h5", [
                _vm._v(_vm._s(_vm.$t("admin.role.individualMedicina")))
              ]),
              _c("b-table", {
                attrs: {
                  striped: "",
                  items: _vm.items,
                  fields: _vm.fields,
                  "table-variant": "light",
                  borderless: "",
                  small: ""
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.itemPackage.length
        ? _c(
            "b-row",
            { staticClass: "custom-row-table" },
            [
              _c("h5", [_vm._v(_vm._s(_vm.$t("admin.role.packageMedicine")))]),
              _c("b-table", {
                attrs: {
                  striped: "",
                  items: _vm.itemPackage,
                  fields: _vm.fields,
                  "table-variant": "light",
                  borderless: "",
                  small: ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "row-details",
                      fn: function(row) {
                        return [
                          _c("b-table", {
                            attrs: {
                              striped: "",
                              items: row.item.data.Componentes,
                              fields: _vm.fieldsPackage,
                              "table-variant": "light",
                              borderless: "",
                              small: ""
                            }
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1575871273
                )
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }